<div class="navigation fixed-top">
  <div class="container">
    <div class="col-lg-12">
      <nav class="navbar navbar-expand-lg">
        <a href="#" class="navbar-brand">LR</a>
        <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
          <!-- <span class="navbar-toggler-icon"></span> -->
          &#9776;
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" [(ngbCollapse)]="isMenuCollapsed">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="scroll-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="scroll-link" href="#">Projects</a>
            </li>
            <li class="nav-item">
              <a class="scroll-link" href="#">Skills</a>
            </li>
            <li class="nav-item">
              <a class="scroll-link" href="#">Experience</a>
            </li>
            <li class="nav-item">
              <a class="scroll-link" href="#">Education</a>
            </li>
            <li class="nav-item">
              <a class="scroll-link" href="#">About</a>
            </li>
            <li class="nav-item">
              <a class="scroll-link" href="#">Contact</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
