<app-header></app-header>
<router-outlet></router-outlet>
<div class="content">
  <div class="paths">
    <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="paths__svg" height="6247px" version="1.1" viewBox="0 0 1920 6247" width="1920px" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd" opacity="0.600317029" stroke="none" stroke-width="1">
        <g stroke="#929292" transform="translate(-209.000000, 0.000000)">
          <g transform="translate(209.000000, 0.000000)">
            <path d="M320.5,0 L320.5,6247" opacity="0.160326087"></path>
            <path d="M640.5,0 L640.5,6247" opacity="0.160326087"></path>
            <path d="M960.5,0 L960.5,6247" opacity="0.160326087"></path>
            <path d="M1280.5,0 L1280.5,6247" opacity="0.160326087"></path>
            <path d="M1600.5,0 L1600.5,6247"  opacity="0.160326087"></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
</div>
